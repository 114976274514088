import "./App.css";
import { Routes, Route, Link } from "react-router-dom";
import { Layout, Typography, Space } from "antd";
import {
  Navbar,
  HomePage,
  CryptoDetails,
  Cryptocurrencies,
  DexScreener,
  Cointelegraph,
  CoinGecko,
  CoinDesk,
  DexNews,
} from "./components";
// import Blockworks from "components/Blockworks";
// import Cointelegraph from "components/Cointelegraph";

function App() {
  return (
    <div className="app"
      style={{
        backgroundColor: "#000000"
      }}
    >
      <div className="navbar">
        <Navbar />
      </div>

      <div className="main"
        style={{
          backgroundColor: "#000000"
        }}
      >
        <Layout
          style={{
            backgroundColor: "#000000"
          }}
        >
          <div className="routes">
            {/* NOTE Routes always wraps the route */}
            <Routes>
              {/* <Route path="/" element={<News URL={'https://blockworks.co?utm_source=soulswap'} />} /> */}
              <Route path="/" element={<DexNews />} />
              <Route path="/coindesk" element={<CoinDesk />} />
              <Route path="/coingecko" element={<CoinGecko />} />
              <Route path="/cointelegraph" element={<Cointelegraph />} />
              <Route path="/dexscreener" element={<DexScreener />} />
              <Route path="/cryptocurrencies"
                element={<Cryptocurrencies simplified={undefined} search={undefined} />}
              />
              <Route path="/crypto/:coinId" element={<CryptoDetails />} />
              {/* <Route path="/news" element={<News simplified={undefined} />} /> */}
            </Routes>
          </div>
        </Layout>

        {/* <div className="footer">
          <Typography.Title
            level={5}
            style={{ color: "white", textAlign: "center" }}
          >
            SoulWallet <br /> All right reservered
          </Typography.Title>
          <Space>
            <Link to="/">Home</Link>
            <Link to="/cryptocurrencies">Cryptocurrencies</Link>
            <Link to="/news">News</Link>
          </Space>
        </div> */}

      </div>
    </div>
  );
}

export default App;
