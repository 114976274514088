import React, { useState, useEffect } from "react"
import { Button, Typography, Menu, Avatar } from "antd"
import { Link } from "react-router-dom"
import {
  HomeOutlined,
  // MoneyCollectOutlined,
  BulbOutlined,
  FundOutlined,
  MenuOutlined,
  FundFilled,
  ContainerFilled,
  FileFilled,
  HomeFilled,
  PhoneFilled,
} from "@ant-design/icons"
// @ts-ignore
import Logo from "../images/logo.png"

const Navbar = () => {
  const width = window?.innerWidth - 4
  const [activeMenu, setActiveMenu] = useState(true);
  const [screenSize, setScreenSize] = useState(null);

  // NOTE trigger only once in the start
  useEffect(() => {
    // @ts-ignore
    const handleResize = () => setScreenSize(window.innerWidth);

    window.addEventListener("resize", handleResize);

    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (Number(screenSize) < 820) {
      setActiveMenu(false);
    } else {
      setActiveMenu(true);
    }
  }, [screenSize]);

  return (
    <div
      style={{
        display: 'flex',
        width: '100%',
        height: 0,
        backgroundColor: '#000000',
        // marginLeft: 4,
        // paddingBottom: -12,
      }}
    >

      <Button
        onClick={() => setActiveMenu(!activeMenu)}
        style={{
          display: 'flex',
          width: width + 'px',
          height: 48,
          justifyContent: 'center',
          alignContent: 'center',
          alignItems: 'center',
          backgroundColor: '#000000',
          borderWidth: 4,
          borderRadius: 12,
          color: '#ffffff',
          // padding: 2,
          marginTop: 24,
        }}
      >
        <MenuOutlined />
      </Button>
      {/* NOTE If activeMenu is true then show the MENU component */}
      {activeMenu && (
        <Menu
          style={{
            display: 'grid',
            width: '100%',
            marginTop: 72,
            padding: 2
          }}
          onClick={() => setActiveMenu(!activeMenu)}
        // theme="dark"        
        >
          <Menu.Item key="news" icon={<HomeFilled />}>
            <Link to="/">News</Link>
          </Menu.Item>
          <Menu.Item key="home" icon={<PhoneFilled />}>
            <Link to="/cointelegraph">Cointelegraph</Link>
          </Menu.Item>
          <Menu.Item key="fund" icon={<FileFilled />}>
            <Link to="/coindesk">CoinDesk</Link>
          </Menu.Item>
          <Menu.Item key="bulb" icon={<ContainerFilled />}>
            <Link to="/coingecko">CoinGecko</Link>
          </Menu.Item>
          <Menu.Item key="fund" icon={<FundFilled />}>
            <Link to="/dexscreener">DexScreener</Link>
          </Menu.Item>
        </Menu>
      )}
    </div>
  );
};

export default Navbar;
