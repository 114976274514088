import React from 'react'

const CoinDesk = () => {
  const width = window.outerWidth
  const height = window.outerHeight

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        flex: 1,
        justifyContent: 'center',
        alignContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: "100%",
      }}
    >
      <iframe
        src={'https://www.coindesk.com?utm_source=soulswap'}
        style={{
          display: 'flex',
          flex: 1,
          justifyContent: 'center',
          alignContent: 'center',
          alignItems: 'center',
          width: width,
          height: height,
          marginTop: -24,
        }}
      />
      </div>
  );
};

export default CoinDesk;
